<template>
  <ConfirmDialog></ConfirmDialog>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">AGENDA</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">CONSULTORIOS</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Especialidades
          <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
          label="NUEVO"
          v-if="'Consultorio Crear' in auth.user.permissions"
          v-tooltip.top="'Nueva Especialidad'"
          icon="pi pi-plus"
          class="p-button-outlined mr-2"
          @click="openNuevo"
        />
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dt_consultorios"
        :value="consultorios"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :rowHover="true"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Especialidades"
        responsiveLayout="scroll"
        selectionMode="single"
        @row-dblclick="editarConsultorio($event.data)"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Consultorios..."
              />
            </span>
          </div>
        </template>

        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span
            class="flex align-items-center justify-content-center"
            style="
              background-color: #f8d7da;
              padding: 5px;
              border-radius: 5px;
              color: #721c24;
            "
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="nombre" header="NOMBRE"></Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>
    <ConsultorioCreate
      :show="consultorioDialog"
      :consultorio="consultorio"
      @closeModal="ocultarDialog"
      @actualizarListado="cargarConsultorios"
    >
    </ConsultorioCreate>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ConsultorioService from "@/service/ConsultorioService";
import { useAuth } from "@/stores";
import ConsultorioCreate from "@/module/consultorios/ConsultorioCreate.vue";

export default {
  components: {
    ConsultorioCreate,
  },
  data() {
    return {
      consultorios: [],
      cargando: true,
      consultorioDialog: false,
      consultorio: {},
      buscar: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      totalRegistros: 0,
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  consultorioService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.consultorioService = new ConsultorioService();
  },
  mounted() {
    this.cargarConsultorios();
  },
  methods: {
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled: "Consultorio Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editarConsultorio(datos);
          },
        },
        {
          label: "Eliminar",
          disabled:
            "Consultorio Eliminar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-trash",
          command: () => {
            this.eliminarConsultorio(datos.id);
          },
        },
      ];
    },
    eliminarConsultorio(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea eliminar Consultorio?",
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, deseo eliminar",
        rejectLabel: "No, cancelar",
        accept: () => {
          this.consultorioService.deleteConsultorio(id).then((response) => {
            if (response.status == 200) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: response.mensaje,
                life: 3000,
              });
              this.cargarConsultorios();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.mensaje,
                life: 3000,
              });
            }
          });
        },
      });
    },
    cargarConsultorios() {
      this.consultorioService.getConsultoriosAll().then((data) => {
        this.consultorios = data.consultorios;
        this.totalRegistros = data.consultorios.length || 0;
        this.cargando = false;
      });
    },
    openNuevo() {
      this.consultorio = {
        estado: { label: "Activo", value: 1 },
      };
      this.enviado = false;
      this.consultorioDialog = true;
    },
    ocultarDialog() {
      this.consultorioDialog = false;
      this.enviado = false;
    },
    editarConsultorio(datos) {
      this.consultorio = {
        ...datos,
        estado: {
          label: datos.estado_texto,
          value: datos.estado,
        },
      };
      this.consultorioDialog = true;

      this.enviado = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>

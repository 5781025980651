<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '550px' }"
    header="Consultorio Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-8">
          <span
            ><strong>NOMBRE: </strong> <span class="p-invalid">*</span></span
          >
          <InputText
            v-model.trim="data_consultorio.nombre"
            required="true"
            placeholder="Nombre Completo"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="estado"
            ><strong>ESTADO: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="estado"
            v-model="data_consultorio.estado"
            :disabled="!data_consultorio.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :label="data_consultorio.id ? 'ACTUALIZAR' : 'GUARDAR'"
        :icon="data_consultorio.id ? 'pi pi-pencil' : 'pi pi-save'"
        class="p-button-primary p-button-lg"
        @click="saveConsultorio"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import ConsultorioService from "@/service/ConsultorioService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    consultorio: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  consultorioService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_consultorio: this.consultorio,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  created() {
    this.consultorioService = new ConsultorioService();
  },
  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
    },
    saveConsultorio() {
      this.errors = {};
      this.enviado = true;
      //editar consultorio
      if (this.consultorio.id) {
        let data_enviar = {
          ...this.data_consultorio,
          estado: this.data_consultorio.estado.value,
        };
        this.consultorioService.updateConsultorio(data_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_consultorio;
        this.data_consultorio = {
          ...this.data_consultorio,
          estado: this.data_consultorio.estado.value,
        };
        this.consultorioService
          .sendConsultorioNew(this.data_consultorio)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_consultorio = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 10000,
              });
              this.$emit("actualizarListado", data.consultorio);
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    consultorio(val) {
      this.data_consultorio = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
